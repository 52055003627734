._1T2a7W_bgd {
  opacity: 1;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  z-index: 999999;
  background: none;
  width: 100%;
  height: 100vh;
  transition: background-color .3s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

._1T2a7W_window {
  background: var(--color-white);
  border-radius: var(--radius-l);
  max-height: calc(100vh - (2 * var(--space-l)));
  opacity: 0;
  padding: var(--space-l);
  width: calc(100% - (2 * var(--space-l)));
  max-width: 700px;
  min-height: 5rem;
  transition: opacity .3s ease-in-out, transform .3s ease-in-out;
  position: fixed;
  top: 50vh;
  left: 50%;
  overflow: auto;
  transform: translate(-50%, -50%)scale(.85);
  box-shadow: 0 0 10px #7272ff0d;
}

._1T2a7W_content {
  box-sizing: border-box;
}

._1T2a7W_opened {
  pointer-events: all;
  background: #000d16e6;
}

._1T2a7W_opened ._1T2a7W_window {
  opacity: 1;
  transform: translate(-50%, -50%)scale(1);
}

._1T2a7W_close {
  color: var(--color-primary);
  cursor: pointer;
  opacity: 1;
  right: var(--space-m);
  top: var(--space-m);
  -webkit-user-select: none;
  user-select: none;
  z-index: 5;
  width: 1.25rem;
  height: 1.25rem;
  transition: opacity .3s ease-in-out;
  display: inline-block;
  position: absolute;
}

._1T2a7W_close:hover {
  opacity: .7;
}

._1T2a7W_close svg {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 450px) {
  ._1T2a7W_window {
    box-sizing: border-box;
    border-radius: 0;
    width: 100%;
    height: 100%;
    top: 5%;
    transform: translate(-50%, 100%);
  }

  ._1T2a7W_opened ._1T2a7W_window {
    opacity: 1;
    bottom: 0;
    transform: translate(-50%);
  }
}

@font-face {
  font-display: swap;
  font-family: Outfit;
  font-style: normal;
  font-weight: 100;
  src: url("QGYvz_MVcBeNP4NJuktqQ4E.3fc8a039.woff2") format("woff2");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-display: swap;
  font-family: Outfit;
  font-style: normal;
  font-weight: 100;
  src: url("QGYvz_MVcBeNP4NJtEtq.eb97299d.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-display: swap;
  font-family: Outfit;
  font-style: normal;
  font-weight: 200;
  src: url("QGYvz_MVcBeNP4NJuktqQ4E.3fc8a039.woff2") format("woff2");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-display: swap;
  font-family: Outfit;
  font-style: normal;
  font-weight: 200;
  src: url("QGYvz_MVcBeNP4NJtEtq.eb97299d.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-display: swap;
  font-family: Outfit;
  font-style: normal;
  font-weight: 300;
  src: url("QGYvz_MVcBeNP4NJuktqQ4E.3fc8a039.woff2") format("woff2");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-display: swap;
  font-family: Outfit;
  font-style: normal;
  font-weight: 300;
  src: url("QGYvz_MVcBeNP4NJtEtq.eb97299d.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-display: swap;
  font-family: Outfit;
  font-style: normal;
  font-weight: 400;
  src: url("QGYvz_MVcBeNP4NJuktqQ4E.3fc8a039.woff2") format("woff2");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-display: swap;
  font-family: Outfit;
  font-style: normal;
  font-weight: 400;
  src: url("QGYvz_MVcBeNP4NJtEtq.eb97299d.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-display: swap;
  font-family: Outfit;
  font-style: normal;
  font-weight: 500;
  src: url("QGYvz_MVcBeNP4NJuktqQ4E.3fc8a039.woff2") format("woff2");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-display: swap;
  font-family: Outfit;
  font-style: normal;
  font-weight: 500;
  src: url("QGYvz_MVcBeNP4NJtEtq.eb97299d.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-display: swap;
  font-family: Outfit;
  font-style: normal;
  font-weight: 600;
  src: url("QGYvz_MVcBeNP4NJuktqQ4E.3fc8a039.woff2") format("woff2");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-display: swap;
  font-family: Outfit;
  font-style: normal;
  font-weight: 600;
  src: url("QGYvz_MVcBeNP4NJtEtq.eb97299d.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-display: swap;
  font-family: Outfit;
  font-style: normal;
  font-weight: 700;
  src: url("QGYvz_MVcBeNP4NJuktqQ4E.3fc8a039.woff2") format("woff2");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-display: swap;
  font-family: Outfit;
  font-style: normal;
  font-weight: 700;
  src: url("QGYvz_MVcBeNP4NJtEtq.eb97299d.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-display: swap;
  font-family: Outfit;
  font-style: normal;
  font-weight: 800;
  src: url("QGYvz_MVcBeNP4NJuktqQ4E.3fc8a039.woff2") format("woff2");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-display: swap;
  font-family: Outfit;
  font-style: normal;
  font-weight: 800;
  src: url("QGYvz_MVcBeNP4NJtEtq.eb97299d.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-display: swap;
  font-family: Outfit;
  font-style: normal;
  font-weight: 900;
  src: url("QGYvz_MVcBeNP4NJuktqQ4E.3fc8a039.woff2") format("woff2");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-display: swap;
  font-family: Outfit;
  font-style: normal;
  font-weight: 900;
  src: url("QGYvz_MVcBeNP4NJtEtq.eb97299d.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
  --color-neutral-50: #fff;
  --color-neutral-100: #e7e7e7;
  --color-neutral-200: #cfcece;
  --color-neutral-300: #b8b6b6;
  --color-neutral-400: #a09e9e;
  --color-neutral-500: #888585;
  --color-neutral-600: #706d6d;
  --color-neutral-700: #585656;
  --color-neutral-800: #3f3e3e;
  --color-neutral-900: #1f1f1f;
  --color-neutral: var(--color-neutral-400);
  --color-primary-50: #eaedfa;
  --color-primary-100: #c0caf1;
  --color-primary-200: #96a6e8;
  --color-primary-300: #6c83df;
  --color-primary-400: #425fd6;
  --color-primary-500: #2945bd;
  --color-primary-600: #203693;
  --color-primary-700: #172769;
  --color-primary-800: #0e173f;
  --color-primary-900: #050815;
  --color-primary: var(--color-primary-400);
  --color-secondary-50: #fde9e7;
  --color-secondary-100: #fabdb8;
  --color-secondary-200: #f79088;
  --color-secondary-300: #f36458;
  --color-secondary-400: #f03829;
  --color-secondary-500: #d61e0f;
  --color-secondary-600: #a7170c;
  --color-secondary-700: #771108;
  --color-secondary-800: #470a05;
  --color-secondary-900: #180302;
  --color-secondary: var(--color-secondary-400);
  --color-success-50: #e8fcf0;
  --color-success-100: #bbf6d3;
  --color-success-200: #8ef1b5;
  --color-success-300: #60eb98;
  --color-success-400: #33e57a;
  --color-success-500: #1acc61;
  --color-success-600: #149f4b;
  --color-success-700: #0e7136;
  --color-success-800: #094420;
  --color-success-900: #03170b;
  --color-success: var(--color-success-400);
  --color-danger-50: #fbeae9;
  --color-danger-100: #f3c0be;
  --color-danger-200: #eb9693;
  --color-danger-300: #e46d68;
  --color-danger-400: #dc433d;
  --color-danger-500: #c22923;
  --color-danger-600: #97201b;
  --color-danger-700: #6c1714;
  --color-danger-800: #410e0c;
  --color-danger-900: #160504;
  --color-danger: var(--color-danger-400);
  --color-warning-50: #fff2e5;
  --color-warning-100: #ffd7b3;
  --color-warning-200: #ffbd80;
  --color-warning-300: #ffa24d;
  --color-warning-400: #ff881a;
  --color-warning-500: #e66f00;
  --color-warning-600: #b35600;
  --color-warning-700: #803d00;
  --color-warning-800: #4d2500;
  --color-warning-900: #1a0c00;
  --color-warning: var(--color-warning-400);
  --color-info-50: #e5ebff;
  --color-info-100: #b3c3ff;
  --color-info-200: #809cff;
  --color-info-300: #4d74ff;
  --color-info-400: #1a4cff;
  --color-info-500: #0033e6;
  --color-info-600: #0028b3;
  --color-info-700: #001c80;
  --color-info-800: #00114d;
  --color-info-900: #00061a;
  --color-info: var(--color-info-400);
  --color-white: var(--color-neutral-50);
  --color-black: var(--color-neutral-900);
  --shadow-50: drop-shadow(0px 4px 4px #00000040);
  --shadow-100: drop-shadow(0px 4px 4px #00000040);
  --shadow-200: drop-shadow(0px 4px 4px #00000040);
  --shadow-300: drop-shadow(0px 4px 4px #00000040);
  --shadow-400: drop-shadow(0px 4px 4px #00000040);
  --shadow-500: drop-shadow(0px 4px 4px #00000040);
  --shadow-600: drop-shadow(0px 4px 4px #00000040);
  --shadow-700: drop-shadow(0px 4px 4px #00000040);
  --shadow-800: drop-shadow(0px 4px 4px #00000040);
  --shadow-900: drop-shadow(0px 4px 4px #00000040);
  --shadow: var(--shadow-400);
  --font-family: outfit, sans-serif;
  --font-size-xxs: 12px;
  --font-size-xs: 14px;
  --font-size-s: 16px;
  --font-size-m: 20px;
  --font-size-l: 24px;
  --font-size-xl: 32px;
  --font-size-xxl: 40px;
  --font-line-xxs: 16px;
  --font-line-xs: 24px;
  --font-line-s: 24px;
  --font-line-m: 32px;
  --font-line-l: 32px;
  --font-line-xl: 48px;
  --font-line-xxl: 48px;
  --space-xxs: 4px;
  --space-xs: 8px;
  --space-s: 12px;
  --space-m: 16px;
  --space-l: 24px;
  --space-xl: 32px;
  --space-xxl: 48px;
  --radius-xs: 2px;
  --radius-s: 4px;
  --radius-m: 8px;
  --radius-l: 16px;
  --radius-xl: 24px;
}

* {
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: #0000;
  font-family: Outfit, sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

.rJcP-q_container {
  margin: auto;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: var(--color-neutral-400);
  cursor: pointer;
  border: 2px solid #0000;
  border-radius: 8px;
  min-height: 50px;
}

::-webkit-scrollbar-track {
  background-color: #0000;
}

::selection {
  background: #425fd64d;
}

.YcNC0W_action {
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

.YcNC0W_action, .YcNC0W_action:visited, .YcNC0W_action:hover, .YcNC0W_action:active {
  text-decoration: none;
}

.YcNC0W_noaction {
  cursor: default;
}

.O69fua_footer {
  background: #222831;
}

.O69fua_footer > div {
  color: var(--color-white);
  margin: auto;
  margin-top: var(--space-xxl);
  padding: var(--space-xxl) 0;
  width: calc(100% - (2 * var(--space-m)));
  place-content: center space-between;
  align-items: center;
  max-width: 1100px;
  text-decoration: none;
  list-style: none;
  display: flex;
}

.O69fua_footer a svg {
  color: var(--color-white);
  width: 30px;
  height: 30px;
  transition: opacity .3s ease-in-out;
}

.O69fua_footer a:hover svg {
  opacity: .85;
}

.O69fua_legal {
  font-weight: 500;
}

.O69fua_legal > * {
  color: var(--color-white);
  margin: 0 var(--space-s);
  transition: opacity .3s ease-in-out;
}

.O69fua_legal > :first-child {
  margin-left: 0;
  font-weight: 700;
}

.O69fua_legal > :last-child {
  margin-right: 0;
}

.O69fua_legal > :hover {
  opacity: .85;
}

.O69fua_icons {
  align-items: center;
  gap: var(--space-s);
  display: flex;
}

@media screen and (max-width: 1000px) {
  .O69fua_footer > div {
    gap: var(--space-m);
    flex-direction: column;
  }

  .O69fua_legal {
    align-items: center;
    gap: var(--space-m);
    text-align: center;
    flex-direction: column;
    display: flex;
  }

  .O69fua_legal > * {
    margin: 0 !important;
  }
}

.RsMs9W_button {
  border-radius: var(--radius-m);
  color: #fff;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: linear-gradient(135deg, #3d68ffcc 0%, #8295e4 100%);
  border: none;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  transition: opacity .3s ease-in-out;
  display: inline-flex;
  box-shadow: inset -4px -4px 12px #fff3, 4px 4px 12px #00000040, inset 0 4px 4px #0000001a;
}

.RsMs9W_button:hover {
  opacity: .85;
}

.RsMs9W_small {
  font-size: var(--font-size-xxs);
  line-height: var(--font-line-xxs);
  padding: 0 var(--space-s);
  height: 32px;
}

.RsMs9W_medium {
  font-size: var(--font-size-xs);
  line-height: var(--font-line-xs);
  padding: 0 var(--space-m);
  height: 40px;
}

.RsMs9W_large {
  font-size: var(--font-size-s);
  line-height: var(--font-line-s);
  padding: 0 var(--space-xl);
  height: 54px;
}

.RsMs9W_neutral {
  background-color: var(--color-neutral-300);
}

.RsMs9W_neutral.RsMs9W_active, .RsMs9W_neutral:hover {
  background-color: var(--color-neutral-400);
}

.RsMs9W_neutral.RsMs9W_disabled {
  background-color: var(--color-neutral-50);
}

.RsMs9W_secondary {
  background-color: var(--color-secondary-300);
}

.RsMs9W_secondary.RsMs9W_active, .RsMs9W_secondary:hover {
  background-color: var(--color-secondary-400);
}

.RsMs9W_secondary.RsMs9W_disabled {
  background-color: var(--color-secondary-50);
}

.RsMs9W_success {
  background-color: var(--color-success-300);
}

.RsMs9W_success.RsMs9W_active, .RsMs9W_success:hover {
  background-color: var(--color-success-400);
}

.RsMs9W_success.RsMs9W_disabled {
  background-color: var(--color-success-50);
}

.RsMs9W_danger {
  background-color: var(--color-danger-300);
}

.RsMs9W_danger.RsMs9W_active, .RsMs9W_danger:hover {
  background-color: var(--color-danger-400);
}

.RsMs9W_danger.RsMs9W_disabled {
  background-color: var(--color-danger-50);
}

.RsMs9W_warning {
  background-color: var(--color-warning-300);
}

.RsMs9W_warning.RsMs9W_active, .RsMs9W_warning:hover {
  background-color: var(--color-warning-400);
}

.RsMs9W_warning.RsMs9W_disabled {
  background-color: var(--color-warning-50);
}

.RsMs9W_info {
  background-color: var(--color-info-300);
}

.RsMs9W_info.RsMs9W_active, .RsMs9W_info:hover {
  background-color: var(--color-info-400);
}

.RsMs9W_info.RsMs9W_disabled {
  background-color: var(--color-info-50);
}

.eJHErG_nav {
  background: #222831;
}

.eJHErG_nav > div {
  padding: var(--space-m) 0;
  width: calc(100% - (2 * var(--space-m)));
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: auto;
  display: flex;
}

.eJHErG_nav ul {
  gap: var(--space-l);
  padding: 0;
  list-style: none;
  display: flex;
}

.eJHErG_nav ul li a, .eJHErG_nav ul li a:visited {
  color: var(--color-white);
  font-size: var(--font-size-s);
  line-height: var(--font-line-s);
  text-decoration: none;
  transition: color .3s;
}

.eJHErG_nav ul li a:hover {
  color: var(--color-primary);
}

.eJHErG_logo {
  max-height: 48px;
}

@media screen and (max-width: 950px) {
  .eJHErG_nav ul {
    display: none;
  }
}

.FstpkG_buttons {
  margin: var(--space-xl) auto;
  text-align: center;
}

._8lvwfW_cards {
  gap: var(--space-m);
  --content-size: calc((100% - (3 * var(--space-m))) / 4);
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

._8lvwfW_cards > * {
  margin: 0;
  width: var(--content-size) !important;
}

@media screen and (max-width: 900px) {
  ._8lvwfW_cards {
    flex-direction: column;
    display: flex;
  }

  ._8lvwfW_cards > * {
    width: 100% !important;
  }
}

.vd2MRW_link {
  color: var(--color-primary);
  letter-spacing: -.32px;
  margin: var(--space-m) 0;
  -webkit-user-select: none;
  user-select: none;
  font-size: 16px;
  line-height: 24px;
  transition: all .3s ease-in-out;
  display: block;
}

.vd2MRW_link:hover {
  opacity: .75;
}

.vd2MRW_link.vd2MRW_white {
  color: var(--color-white);
}

.iFw43G_card {
  background-color: var(--color-white);
  border-radius: var(--radius-l);
  box-sizing: border-box;
  color: var(--color-black);
  padding: var(--space-m);
  text-decoration: none;
  list-style: none;
  box-shadow: 0 6px 12px #14142b0d;
}

.Rq15Ja_feature {
  flex-direction: column;
  align-items: center;
  animation-name: Rq15Ja_animate;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-fill-mode: backwards;
  display: flex;
}

.Rq15Ja_feature h1 {
  font-size: var(--font-size-l);
  font-weight: 500;
  line-height: var(--font-line-l);
  margin: 0;
  margin-bottom: var(--space-s);
  margin-top: var(--space-s);
  text-align: center;
}

.Rq15Ja_feature p {
  font-size: var(--font-size-s);
  line-height: var(--font-line-s);
  text-align: justify;
  margin: 0;
}

.Rq15Ja_feature svg {
  color: var(--color-primary);
  width: 30px;
  height: 30px;
}

@keyframes Rq15Ja_animate {
  0% {
    opacity: 0;
    transform: scale(.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.oWQxdW_section {
  position: relative;
}

.oWQxdW_section > div {
  margin: auto;
  margin-top: calc(3 * var(--space));
  width: calc(100% - (2 * var(--space-m)));
  max-width: 1100px;
}

.oWQxdW_banner {
  color: var(--color-white);
  padding: var(--space-xxl) var(--space-m);
  background: #222831;
  align-items: center;
  min-height: 500px;
  display: flex;
}

.oWQxdW_smallBanner {
  color: var(--color-primary);
  margin: var(--space-xxl) 0;
  padding: var(--space-m);
  background: none;
  align-items: center;
  min-height: 500px;
  display: flex;
}

.oWQxdW_banner > div {
  margin-top: auto;
}

.SKMqSa_user {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
  padding: var(--space-l) var(--space-m) !important;
}

.SKMqSa_user h1 {
  font-size: var(--font-size-m);
  font-weight: 600;
  line-height: var(--font-line-m);
  margin: 0;
  margin-top: var(--space-s);
}

.SKMqSa_user h2 {
  font-size: var(--font-size-s);
  font-weight: 400;
  line-height: var(--font-line-s);
  margin: 0;
}

.SKMqSa_user img {
  color: var(--color-primary);
  object-fit: cover;
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.SKMqSa_icons {
  gap: var(--space-s);
  margin-top: var(--space-s);
  display: flex;
}

.SKMqSa_icons a svg {
  color: var(--color-primary-300);
  width: 20px;
  height: 20px;
  transition: color .3s ease-in-out;
}

.SKMqSa_icons a:hover svg {
  color: var(--color-primary-400) !important;
}

.SKMqSa_description {
  border-radius: var(--radius-l);
  color: var(--color-white);
  padding: var(--space-m);
  background: #222831;
  flex-direction: column;
  place-content: space-between;
  align-items: center;
  font-size: 12px;
  transition: transform .4s ease-in-out;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translate(0%, 101%);
}

@media (pointer: coarse) {
  .SKMqSa_description {
    margin: var(--space-m) 0;
    margin-bottom: 0;
    position: relative;
    transform: none;
  }

  .SKMqSa_description .SKMqSa_icons {
    display: none;
  }
}

.SKMqSa_user:hover .SKMqSa_description {
  transform: translate(0);
}

.SKMqSa_description .SKMqSa_icons a svg {
  color: var(--color-neutral-100);
}

.SKMqSa_description .SKMqSa_icons a:hover svg {
  color: var(--color-neutral-400) !important;
}

.m_roHW_base {
  margin: var(--space-xl) auto;
  overflow-wrap: anywhere;
  text-align: center;
  font-weight: 500;
}

.m_roHW_base strong {
  color: var(--color-primary);
}

.m_roHW_title {
  letter-spacing: -3.84px;
  font-size: 82px;
  font-weight: 600;
  line-height: 112px;
}

.m_roHW_subtitle {
  letter-spacing: -.64px;
  font-size: 28px;
  font-weight: 300;
  line-height: 48px;
}

.m_roHW_titleSmall {
  letter-spacing: -4px;
  text-align: center;
  font-size: 76px;
  font-weight: 600;
}

.m_roHW_subtitleSmall {
  color: #575d66;
  letter-spacing: -.48px;
  text-align: center;
  max-width: 780px;
  font-size: 24px;
  line-height: 32px;
}

.m_roHW_section {
  color: var(--color-primary);
  font-size: var(--font-size-xl);
  line-height: var(--font-line-xl);
  margin: var(--space-l) 0;
  margin-top: var(--space-xxl);
}

@media screen and (max-width: 1175px) {
  .m_roHW_title {
    font-size: 60px;
    line-height: 64px;
  }

  .m_roHW_subtitle {
    font-size: 20px;
    line-height: 24px;
  }

  .m_roHW_titleSmall {
    font-size: 50px;
    line-height: 54px;
  }

  .m_roHW_subtitleSmall {
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (max-width: 850px) {
  .m_roHW_title {
    letter-spacing: 0;
    font-size: 40px;
    line-height: 44px;
  }

  .m_roHW_subtitle {
    letter-spacing: 0;
    font-size: 20px;
    line-height: 24px;
  }

  .m_roHW_titleSmall {
    letter-spacing: 0;
    font-size: 40px;
    line-height: 44px;
  }

  .m_roHW_subtitleSmall {
    letter-spacing: 0;
    font-size: 20px;
    line-height: 24px;
  }
}

.LhixWW_logo {
  margin: auto;
  margin-bottom: calc(-1 * var(--space-m));
  display: block;
}

.O1FfUW_badge {
  transition: transform .3 ease-in-out;
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  width: 210px;
  min-width: 210px;
  height: 60px;
  display: block;
}

.O1FfUW_badge:hover {
  filter: brightness(1.25);
}

.xr-dUG_badgeContainer {
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin: 50px;
  display: flex;
}

@media screen and (max-width: 680px) {
  .xr-dUG_badgeContainer {
    flex-direction: column;
  }
}

/*# sourceMappingURL=index.cdfb0a38.css.map */
